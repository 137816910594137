import React from "react";
import googleRating from "../../../../static/images/google-rating.png";


const VideoSection = (props) => {
    const [showDetails2, setShowDetails2] = React.useState(false);
    const { title, paragraph1, paragraph2, paragraph3} = props;
    return (
        <section className="internet-section3">
            <div>
                <div>
                    <div className="internet-section3__left iframe-container">
                        <div className="">
                                {/* // title="Purple Cow Internet - Internet Provider Nova Scotia, Prince Edward Island, Newfoundland" */}
                            <iframe
                                width="1120" 
                                height="630"
                                src="https://www.youtube.com/embed/m8-2Lq1MlgU?si=5uS2SEsGywqPqdXZ"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoSection